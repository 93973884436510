define("discourse/plugins/discourse-new-features-feeds/discourse/models/feature", ["exports", "@glimmer/tracking"], function (_exports, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class Feature {
    static #_ = (() => dt7948.g(this.prototype, "id", [_tracking.tracked], function () {
      return null;
    }))();
    #id = (() => (dt7948.i(this, "id"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "emoji", [_tracking.tracked], function () {
      return null;
    }))();
    #emoji = (() => (dt7948.i(this, "emoji"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "title", [_tracking.tracked], function () {
      return null;
    }))();
    #title = (() => (dt7948.i(this, "title"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "description", [_tracking.tracked], function () {
      return null;
    }))();
    #description = (() => (dt7948.i(this, "description"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "link", [_tracking.tracked], function () {
      return null;
    }))();
    #link = (() => (dt7948.i(this, "link"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "tier", [_tracking.tracked], function () {
      return null;
    }))();
    #tier = (() => (dt7948.i(this, "tier"), void 0))();
    static #_7 = (() => dt7948.g(this.prototype, "screenshot_url", [_tracking.tracked], function () {
      return null;
    }))();
    #screenshot_url = (() => (dt7948.i(this, "screenshot_url"), void 0))();
    static #_8 = (() => dt7948.g(this.prototype, "discourse_version", [_tracking.tracked], function () {
      return null;
    }))();
    #discourse_version = (() => (dt7948.i(this, "discourse_version"), void 0))();
    static #_9 = (() => dt7948.g(this.prototype, "released_at", [_tracking.tracked], function () {
      return null;
    }))();
    #released_at = (() => (dt7948.i(this, "released_at"), void 0))();
    static #_10 = (() => dt7948.g(this.prototype, "screenshot_or_emoji", [_tracking.tracked], function () {
      return null;
    }))();
    #screenshot_or_emoji = (() => (dt7948.i(this, "screenshot_or_emoji"), void 0))();
    static #_11 = (() => dt7948.g(this.prototype, "experiment", [_tracking.tracked], function () {
      return null;
    }))();
    #experiment = (() => (dt7948.i(this, "experiment"), void 0))();
    static #_12 = (() => dt7948.g(this.prototype, "experiment_setting", [_tracking.tracked], function () {
      return null;
    }))();
    #experiment_setting = (() => (dt7948.i(this, "experiment_setting"), void 0))();
    static #_13 = (() => dt7948.g(this.prototype, "plugin_name", [_tracking.tracked], function () {
      return null;
    }))();
    #plugin_name = (() => (dt7948.i(this, "plugin_name"), void 0))();
    constructor(currentUser, feature) {
      if (currentUser) {
        this.released_at = moment.tz(currentUser.user_option.timezone).format("YYYY-MM-DD");
      }
      if (!feature) {
        return;
      }
      this.id = feature.id;
      this.emoji = feature.emoji;
      this.title = feature.title;
      this.description = feature.description;
      this.link = feature.link;
      this.tier = feature.tier;
      this.screenshot_url = feature.screenshot_url;
      this.discourse_version = feature.discourse_version;
      this.released_at = feature.released_at;
      this.screenshot_or_emoji = feature.screenshot_or_emoji;
      this.experiment = feature.experiment;
      this.experiment_setting = feature.experiment_setting;
      this.plugin_name = feature.plugin_name;
    }
  }
  _exports.default = Feature;
});