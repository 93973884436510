define("discourse/plugins/discourse-new-features-feeds/admin-discourse-new-features-feeds-route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    resource: "admin.adminPlugins.show",
    path: "/plugins",
    map() {
      this.route("discourse-new-features-feeds", {
        path: "/features"
      }, function () {
        this.route("edit", {
          path: "/:id"
        });
        this.route("new", {}, function () {
          this.route("copy", {
            path: "/:id"
          });
        });
      });
    }
  };
});