define("discourse/plugins/discourse-new-features-feeds/discourse/routes/admin-plugins/show/discourse-new-features-feeds/index", ["exports", "discourse/lib/ajax", "discourse/routes/discourse"], function (_exports, _ajax, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class IndexRoute extends _discourse.default {
    model() {
      return (0, _ajax.ajax)("/admin/plugins/discourse-new-features-feeds.json");
    }
  }
  _exports.default = IndexRoute;
});