define("discourse/plugins/discourse-customer-flair-plugin/discourse/lib/slo-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.SLO_PASSED = _exports.SLO_APPROACHING = void 0;
  _exports.default = sloStatus;
  const SLO_APPROACHING = _exports.SLO_APPROACHING = "slo_approaching";
  const SLO_PASSED = _exports.SLO_PASSED = "slo_passed";
  function sloStatus(topic) {
    const rawSloTime = topic.get("slo_time");
    if (!rawSloTime) {
      return;
    }
    const sloTime = moment(rawSloTime);
    if (sloTime.isBefore(moment())) {
      return SLO_PASSED;
    } else if (sloTime.isBefore(moment().add(1, "hour"))) {
      return SLO_APPROACHING;
    }
    return null;
  }
});