define("discourse/plugins/discourse-new-features-feeds/discourse/routes/admin-plugins/show/discourse-new-features-feeds/new/copy", ["exports", "@ember/service", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/routes/discourse", "discourse/plugins/discourse-new-features-feeds/discourse/models/feature"], function (_exports, _service, _ajax, _ajaxError, _discourse, _feature) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class NewCopyRoute extends _discourse.default {
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    async model(params) {
      const id = parseInt(params.id, 10);
      try {
        const response = await (0, _ajax.ajax)(`/admin/plugins/discourse-new-features-feeds/features/new/${id}`);
        return new _feature.default(this.currentUser, response.new_feature_feeds);
      } catch (error) {
        (0, _ajaxError.popupAjaxError)(error);
      }
    }
  }
  _exports.default = NewCopyRoute;
});