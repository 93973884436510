define("discourse/plugins/discourse-customer-flair-plugin/discourse/connectors/topic-list-before-link/content-warning", ["exports", "@glimmer/component", "discourse-common/helpers/d-icon", "@ember/component", "@ember/template-factory"], function (_exports, _component, _dIcon, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class WhisperIndicator extends _component.default {
    static shouldRender(args) {
      return args.topic.get("has_content_warning");
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{icon "triangle-exclamation" translatedTitle="Content Warning"}}
      
    */
    {
      "id": "/8gHqGyq",
      "block": "[[[1,\"\\n    \"],[1,[28,[32,0],[\"triangle-exclamation\"],[[\"translatedTitle\"],[\"Content Warning\"]]]],[1,\"\\n  \"]],[],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-customer-flair-plugin/discourse/connectors/topic-list-before-link/content-warning.js",
      "scope": () => [_dIcon.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = WhisperIndicator;
});