define("discourse/plugins/discourse-new-features-feeds/discourse/templates/admin-plugins/show/discourse-new-features-feeds/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <section class="discourse-new-features-table">
    <DPageSubheader @titleLabel={{i18n "new_feature_feeds.table_header"}}>
      <:actions as |actions|>
        <actions.Primary
          @label="new_feature_feeds.create"
          @route="adminPlugins.show.discourse-new-features-feeds.new"
          class="discourse-new-features__create-btn"
        />
      </:actions>
    </DPageSubheader>
  
    {{#if this.model.new_features}}
      <NewFeaturesTable
        @features={{this.model.new_features}}
        @refresh={{this.refresh}}
      />
    {{else}}
      <AdminConfigAreaEmptyList
        @ctaLabel="new_feature_feeds.create"
        @ctaRoute="adminPlugins.show.discourse-new-features-feeds.new"
        @ctaClass="discourse-new-features__create-btn"
        @emptyLabel="new_feature_feeds.no_items"
      />
    {{/if}}
  </section>
  */
  {
    "id": "2GCIppp8",
    "block": "[[[10,\"section\"],[14,0,\"discourse-new-features-table\"],[12],[1,\"\\n  \"],[8,[39,1],null,[[\"@titleLabel\"],[[28,[37,2],[\"new_feature_feeds.table_header\"],null]]],[[\"actions\"],[[[[1,\"\\n      \"],[8,[30,1,[\"Primary\"]],[[24,0,\"discourse-new-features__create-btn\"]],[[\"@label\",\"@route\"],[\"new_feature_feeds.create\",\"adminPlugins.show.discourse-new-features-feeds.new\"]],null],[1,\"\\n    \"]],[1]]]]],[1,\"\\n\\n\"],[41,[30,0,[\"model\",\"new_features\"]],[[[1,\"    \"],[8,[39,5],null,[[\"@features\",\"@refresh\"],[[30,0,[\"model\",\"new_features\"]],[30,0,[\"refresh\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,6],null,[[\"@ctaLabel\",\"@ctaRoute\",\"@ctaClass\",\"@emptyLabel\"],[\"new_feature_feeds.create\",\"adminPlugins.show.discourse-new-features-feeds.new\",\"discourse-new-features__create-btn\",\"new_feature_feeds.no_items\"]],null],[1,\"\\n\"]],[]]],[13]],[\"actions\"],false,[\"section\",\"d-page-subheader\",\"i18n\",\":actions\",\"if\",\"new-features-table\",\"admin-config-area-empty-list\"]]",
    "moduleName": "discourse/plugins/discourse-new-features-feeds/discourse/templates/admin-plugins/show/discourse-new-features-feeds/index.hbs",
    "isStrictMode": false
  });
});