define("discourse/plugins/discourse-new-features-feeds/discourse/lib/feature-tiers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.FEATURE_TIERS = void 0;
  // Make sure to update NewFeatureFeedItem.tiers too!
  const FEATURE_TIERS = _exports.FEATURE_TIERS = [{
    id: 0,
    name: "standard"
  }, {
    id: 1,
    name: "business"
  }, {
    id: 2,
    name: "enterprise"
  }, {
    id: 3,
    name: "enhanced enterprise"
  }, {
    id: 4,
    name: "free"
  }, {
    id: 5,
    name: "basic"
  }, {
    id: 6,
    name: "starter"
  }, {
    id: 7,
    name: "probono"
  }];
});