define("discourse/plugins/discourse-customer-flair-plugin/discourse/api-initializers/slo-topic-list", ["exports", "discourse/components/topic-list/header/sortable-column", "discourse/helpers/format-date", "discourse/lib/api", "discourse-i18n", "discourse/plugins/discourse-customer-flair-plugin/discourse/components/priority-cell", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _sortableColumn, _formatDate, _api, _discourseI18n, _priorityCell, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const LastReponseHeader = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <SortableColumn
      @sortable={{@sortable}}
      @order="last_response"
      @activeOrder={{@activeOrder}}
      @changeSort={{@changeSort}}
      @ascending={{@ascending}}
      @name="support_column.last_response"
      aria-label={{i18n "support_column.sr_last_response"}}
    />
  
  */
  {
    "id": "qqqfFLFp",
    "block": "[[[1,\"\\n  \"],[8,[32,0],[[16,\"aria-label\",[28,[32,1],[\"support_column.sr_last_response\"],null]]],[[\"@sortable\",\"@order\",\"@activeOrder\",\"@changeSort\",\"@ascending\",\"@name\"],[[30,1],\"last_response\",[30,2],[30,3],[30,4],\"support_column.last_response\"]],null],[1,\"\\n\"]],[\"@sortable\",\"@activeOrder\",\"@changeSort\",\"@ascending\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-customer-flair-plugin/discourse/api-initializers/slo-topic-list.js",
    "scope": () => [_sortableColumn.default, _discourseI18n.i18n],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "slo-topic-list:LastReponseHeader"));
  const PriorityHeader = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <SortableColumn
      @sortable={{@sortable}}
      @order="priority"
      @activeOrder={{@activeOrder}}
      @changeSort={{@changeSort}}
      @ascending={{@ascending}}
      @name="support_column.priority"
      aria-label={{i18n "support_column.sr_priority"}}
    />
  
  */
  {
    "id": "5v3sHB06",
    "block": "[[[1,\"\\n  \"],[8,[32,0],[[16,\"aria-label\",[28,[32,1],[\"support_column.sr_priority\"],null]]],[[\"@sortable\",\"@order\",\"@activeOrder\",\"@changeSort\",\"@ascending\",\"@name\"],[[30,1],\"priority\",[30,2],[30,3],[30,4],\"support_column.priority\"]],null],[1,\"\\n\"]],[\"@sortable\",\"@activeOrder\",\"@changeSort\",\"@ascending\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-customer-flair-plugin/discourse/api-initializers/slo-topic-list.js",
    "scope": () => [_sortableColumn.default, _discourseI18n.i18n],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "slo-topic-list:PriorityHeader"));
  const LastResponseCell = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <td class="last_response">
      <a href={{@topic.lastPostUrl}}>
        {{formatDate @topic.last_responded_at format="tiny"}}
      </a>
    </td>
  
  */
  {
    "id": "SjqO8sRl",
    "block": "[[[1,\"\\n  \"],[10,\"td\"],[14,0,\"last_response\"],[12],[1,\"\\n    \"],[10,3],[15,6,[30,1,[\"lastPostUrl\"]]],[12],[1,\"\\n      \"],[1,[28,[32,0],[[30,1,[\"last_responded_at\"]]],[[\"format\"],[\"tiny\"]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"@topic\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-customer-flair-plugin/discourse/api-initializers/slo-topic-list.js",
    "scope": () => [_formatDate.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "slo-topic-list:LastResponseCell"));
  var _default = _exports.default = (0, _api.apiInitializer)("1.34.0", api => {
    api.registerValueTransformer("topic-list-columns", _ref => {
      let {
        value: columns
      } = _ref;
      const router = api.container.lookup("service:router");
      const currentUser = api.container.lookup("service:currentUser");
      if (currentUser?.staff && (router.currentRouteName.startsWith("group.messages.") || router.currentRouteName.startsWith("userPrivateMessages."))) {
        columns.add("last-response", {
          header: LastReponseHeader,
          item: LastResponseCell
        }, {
          after: "activity"
        });
        columns.add("priority", {
          header: PriorityHeader,
          item: _priorityCell.default
        }, {
          after: "last-response"
        });
      }
      return columns;
    });
  });
});