define("discourse/plugins/discourse-customer-flair-plugin/discourse/raw-templates/connectors/topic-list-header-after/slo-columns", ["exports", "discourse/lib/raw-handlebars", "discourse/lib/raw-templates"], function (_exports, _rawHandlebars, _rawTemplates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let template = (0, _rawHandlebars.template)({
    "compiler": [8, ">= 4.3.0"],
    "main": function (container, depth0, helpers, partials, data) {
      var alias1 = depth0 != null ? depth0 : container.nullContext || {},
        alias2 = container.hooks.helperMissing,
        alias3 = container.escapeExpression,
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return alias3((lookupProperty(helpers, "raw") || depth0 && lookupProperty(depth0, "raw") || alias2).call(alias1, "topic-list-header-column", {
        "name": "raw",
        "hash": {
          "ariaLabel": (lookupProperty(helpers, "i18n") || depth0 && lookupProperty(depth0, "i18n") || alias2).call(alias1, "support_column.sr_last_response", {
            "name": "i18n",
            "hash": {},
            "hashTypes": {},
            "hashContexts": {},
            "types": ["StringLiteral"],
            "contexts": [depth0],
            "data": data,
            "loc": {
              "start": {
                "line": 2,
                "column": 117
              },
              "end": {
                "line": 2,
                "column": 157
              }
            }
          }),
          "name": "support_column.last_response",
          "order": "last_response",
          "sortable": "true"
        },
        "hashTypes": {
          "ariaLabel": "SubExpression",
          "name": "StringLiteral",
          "order": "StringLiteral",
          "sortable": "StringLiteral"
        },
        "hashContexts": {
          "ariaLabel": depth0,
          "name": depth0,
          "order": depth0,
          "sortable": depth0
        },
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 2,
            "column": 0
          },
          "end": {
            "line": 2,
            "column": 159
          }
        }
      })) + "\n" + alias3((lookupProperty(helpers, "raw") || depth0 && lookupProperty(depth0, "raw") || alias2).call(alias1, "topic-list-header-column", {
        "name": "raw",
        "hash": {
          "ariaLabel": (lookupProperty(helpers, "i18n") || depth0 && lookupProperty(depth0, "i18n") || alias2).call(alias1, "support_column.sr_priority", {
            "name": "i18n",
            "hash": {},
            "hashTypes": {},
            "hashContexts": {},
            "types": ["StringLiteral"],
            "contexts": [depth0],
            "data": data,
            "loc": {
              "start": {
                "line": 3,
                "column": 107
              },
              "end": {
                "line": 3,
                "column": 142
              }
            }
          }),
          "name": "support_column.priority",
          "order": "priority",
          "sortable": "true"
        },
        "hashTypes": {
          "ariaLabel": "SubExpression",
          "name": "StringLiteral",
          "order": "StringLiteral",
          "sortable": "StringLiteral"
        },
        "hashContexts": {
          "ariaLabel": depth0,
          "name": depth0,
          "order": depth0,
          "sortable": depth0
        },
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 3,
            "column": 0
          },
          "end": {
            "line": 3,
            "column": 144
          }
        }
      })) + "\n";
    },
    "useData": true
  });
  (0, _rawTemplates.addRawTemplate)("javascripts/connectors/topic-list-header-after/slo-columns", template, {
    core: false,
    pluginName: "discourse-customer-flair-plugin",
    hasModernReplacement: true
  });
  var _default = _exports.default = template;
});