define("discourse/plugins/discourse-new-features-feeds/discourse/routes/admin-plugins/show/discourse-new-features-feeds/new/index", ["exports", "@ember/service", "discourse/routes/discourse", "discourse/plugins/discourse-new-features-feeds/discourse/models/feature"], function (_exports, _service, _discourse, _feature) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class NewIndexRoute extends _discourse.default {
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    model() {
      return new _feature.default(this.currentUser);
    }
  }
  _exports.default = NewIndexRoute;
});