define("discourse/plugins/discourse-customer-flair-plugin/discourse/raw-templates/connectors/topic-list-after-columns/slo-columns", ["exports", "discourse/lib/raw-handlebars", "discourse/lib/raw-templates"], function (_exports, _rawHandlebars, _rawTemplates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let template = (0, _rawHandlebars.template)({
    "compiler": [8, ">= 4.3.0"],
    "main": function (container, depth0, helpers, partials, data) {
      var alias1 = depth0 != null ? depth0 : container.nullContext || {},
        alias2 = container.escapeExpression,
        alias3 = container.hooks.helperMissing,
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return "<td class=\"last_response\">\n  <a href=\"" + alias2(lookupProperty(helpers, "get").call(alias1, "context.topic.lastPostUrl", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 3,
            "column": 11
          },
          "end": {
            "line": 3,
            "column": 40
          }
        }
      })) + "\">\n    " + alias2((lookupProperty(helpers, "format-date") || depth0 && lookupProperty(depth0, "format-date") || alias3).call(alias1, "context.topic.last_responded_at", {
        "name": "format-date",
        "hash": {
          "format": "tiny"
        },
        "hashTypes": {
          "format": "StringLiteral"
        },
        "hashContexts": {
          "format": depth0
        },
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 4,
            "column": 4
          },
          "end": {
            "line": 4,
            "column": 65
          }
        }
      })) + "\n  </a>\n</td>\n" + alias2((lookupProperty(helpers, "raw") || depth0 && lookupProperty(depth0, "raw") || alias3).call(alias1, "priority-column", {
        "name": "raw",
        "hash": {
          "topic": "context.topic"
        },
        "hashTypes": {
          "topic": "PathExpression"
        },
        "hashContexts": {
          "topic": depth0
        },
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 7,
            "column": 0
          },
          "end": {
            "line": 7,
            "column": 45
          }
        }
      })) + "\n";
    },
    "useData": true
  });
  (0, _rawTemplates.addRawTemplate)("javascripts/connectors/topic-list-after-columns/slo-columns", template, {
    core: false,
    pluginName: "discourse-customer-flair-plugin",
    hasModernReplacement: true
  });
  var _default = _exports.default = template;
});