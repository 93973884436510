define("discourse/plugins/discourse-new-features-feeds/discourse/templates/admin-plugins/show/discourse-new-features-feeds/new/copy", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="admin-detail discourse-new-feature-feeds-copy">
    <BackButton
      @label="new_feature_feeds.back"
      @route="adminPlugins.show.discourse-new-features-feeds.index"
      class="discourse-new-feature-feeds-back"
    />
    <AdminConfigAreaCard @heading="new_feature_feeds.new_header">
      <:content>
        <NewFeatureForm
          @form={{this.model}}
          @currentVersion={{this.model.meta.current_version}}
        />
      </:content>
    </AdminConfigAreaCard>
  </div>
  */
  {
    "id": "RGojzHMV",
    "block": "[[[10,0],[14,0,\"admin-detail discourse-new-feature-feeds-copy\"],[12],[1,\"\\n  \"],[8,[39,1],[[24,0,\"discourse-new-feature-feeds-back\"]],[[\"@label\",\"@route\"],[\"new_feature_feeds.back\",\"adminPlugins.show.discourse-new-features-feeds.index\"]],null],[1,\"\\n  \"],[8,[39,2],null,[[\"@heading\"],[\"new_feature_feeds.new_header\"]],[[\"content\"],[[[[1,\"\\n      \"],[8,[39,4],null,[[\"@form\",\"@currentVersion\"],[[30,0,[\"model\"]],[30,0,[\"model\",\"meta\",\"current_version\"]]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"],[13]],[],false,[\"div\",\"back-button\",\"admin-config-area-card\",\":content\",\"new-feature-form\"]]",
    "moduleName": "discourse/plugins/discourse-new-features-feeds/discourse/templates/admin-plugins/show/discourse-new-features-feeds/new/copy.hbs",
    "isStrictMode": false
  });
});